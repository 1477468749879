///////////////// BODY /////////////////

.content-wrapper {
    position: relative;
    padding: 5rem;
    height: 100%;
}

@include breakpoint {
    .content-wrapper {
        padding: 2rem 1.5rem;
        padding-top: 7em;
    }
}