///////////////// HEADER /////////////////

.header {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 5rem;
    margin-left: 5rem;
    width: 18rem;
    z-index: 10;
}

.header-title {
    margin-bottom: 3rem;
    color: $dark;
    font-size: 1.1em;
    font-weight: 800;
}

@include breakpoint {
    .header {
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 2rem 2rem 0;
        width: auto;
    }

    .header-title {
        margin-bottom: 0;
        font-size: 1.8rem;
    }
}