///////////////// GRID /////////////////

[class^="col-"] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.col-2 > div {
    width: 50%;

    &:nth-child(odd) {
        margin-right: 1rem;
        width: calc(50% - 1rem);
    }

    @include breakpoint {
        &,
        &:nth-child(odd) {
            margin-right: 0;
            width: 100%;
        }
    }
} 


.mt-25 {
    margin-top: 2.5rem;
}

.mb-25 {
    margin-bottom: 2.5rem;
}