////////////////// BREAKPOINTS //////////////////
@mixin breakpoint($size: default) {
    @if $size == default {
        // < 800 px
        $size: 50.000em;
    }

    @media only screen and (max-width: $size) {
        @content;
    }
}
