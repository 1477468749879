////////////////// THEME //////////////////
////////////////// COLORS
$white      : #fff;
$light      : #eee;
$black      : #000;
$dark       : #050805;
$grey       : #666;
$lightgrey  : lighten($grey, 30%);
////////////////// TEXT SIZES
@mixin size($size : default) {
    @if $size == default {
        font-size: 1.5rem;
    }

    @else if $size == smaller {
        font-size: 1rem;
    }

    @else if $size == small {
        font-size: 1.3rem;
    }
}