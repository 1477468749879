/////////////////////// RESET ///////////////////////

*,
*:after,
*:before {
    box-sizing: border-box;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
em,
img,
ol,
ul,
li,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
footer,
header,
menu,
nav,
output,
section,
audio,
video,
figcaption,
figure,
fieldset {
    margin: 0px;
    padding: 0px;

    border: 0;

    font: inherit;
    vertical-align: baseline;

    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;

    -webkit-margin-start: 0em;
    -webkit-margin-end: 0em;

    -webkit-padding-start: 0em;
    -webkit-padding-end: 0em;

    -webkit-padding-before: 0em;
    -webkit-padding-after: 0em;
}

body {
    width: 100%;
    height: 100vh;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
label {
    display: block;
}

em,
i,
strong,
b,
u,
a,
button,
input,
select,
span {
    display: inline-block;
}

em,
i {
    font-style: normal;
}

strong,
b {
    font-weight: normal;
}

u {
    text-decoration: none;
    border-width: 0 0 1px;
    border-style: solid;
}

ol,
ul {
    list-style: none;
}

button,
input,
select,
textarea {
    padding: 0;
    margin: 0;
    border: 0;
    color: inherit;

    font: inherit;
    background: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

label,
select,
option,
button,
a {
    cursor: pointer;
}

a,
button,
input,
select {
    border-radius: 0;
}

a,
a:link,
a:active,
a:focus,
a:hover,
a:visited {
    color: inherit;
    text-decoration: none;
}

img {
    display: block;
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

input[type="radio"],
input[type="checkbox"],
input[type="button"] {
    margin: 0;
    padding: 0;

    height: auto;
}

[disabled="true"],
[disabled="disabled"] {
    opacity: .5;
    cursor: not-allowed;
}

[disabled="true"]>*,
[disabled="disabled"]>* {
    pointer-events: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

address {
    font-style: normal;
}