///////////////// FORM /////////////////

/* Honeypot input */
#user {
    display: none;
}
// end

form {
    display: flex;
    flex-direction: column;
}

.form-label {
    font-weight: bold;
}

.form-input {
    margin: .5rem 0 2.5rem;
    padding: 1rem;
    width: 100%;
    background-color: $light;
    border: .1rem solid darken($light, 10%);
    border-radius: .2rem;

    @include breakpoint {
        margin-bottom: 1.5rem;
    }

    @at-root textarea#{&} {
        min-width: 100%;
        max-width: 100%;
        height: 10rem;
        min-height: 10rem;
        max-height: 10rem;
        resize: none;
    }
}

.form-input-wrapper {
    margin-bottom: 2.5rem;

    @include breakpoint {
        margin-bottom: 1.5rem;
    }
    
    .form-input {
        margin-bottom: .5rem;
    }
}

.form-button {
    margin-left: auto;
    padding: 1rem 3rem;
    color: $light;
    background-color: $dark;
    @include opacity(1, .8);

    @include breakpoint {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}

///////////////// ALERTS
@mixin alert {
    padding: .75rem 1.2rem;
    margin-bottom: 1rem;
    border: .1rem solid transparent;
    border-radius: .2rem;
}

$error: #721c24;
$success: #155724;

.form-error {
    @include alert;
    color: $error;
    background-color: lighten($error, 60%);
    border-color: lighten($error, 10%);
}

.form-success {
    @include alert;
    color: $success;
    background-color: lighten($success, 60%);
    border-color: lighten($success, 10%);
}