///////////////// TINY SLIDER /////////////////
.tns-outer,
.tns-ovh,
.tns-inner,
.slider,
.tns-item {
    height: 100%;
    @include breakpoint {
        height: auto;
    }
}

.tns-outer {
    @include breakpoint {
        margin-bottom: 2rem;
    }
}

///////////////// SLIDER /////////////////

.slider-container {
    position: relative;
    height: 100%;
}    

.slider-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;

    @include breakpoint {
        flex-direction: column-reverse;
        padding-top: .5rem;
        height: auto;
    }
}

.slider-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 0 2rem;
    height: 100%;

    @include breakpoint {
        flex: 1 0 auto;
        margin: 0 auto;
        padding: 0;
        height: calc(100% - 145px);
    }
}

.slider-aside {
    width: 18rem;
    flex-shrink: 0;

    @include breakpoint {
        flex: 0 0 auto;
        padding: 3rem 0 0;
        width: 100%;
        font-size: 1.5rem;
        line-height: 1;
    }

    div:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    .slider-thumbnails-btn {
        @include breakpoint {
            margin-bottom: 2.5rem;
        }
    }
}

.slider-title {
    color: $dark;
    font-weight: bold;
}

.slider-count {
    min-width: 3rem;
    text-align: center;

    @include breakpoint {
        font-size: 1.5rem;
    }
}

///////////////// SLIDER CONTROLS /////////////////
.tns-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 18rem;
    width: calc(100% - 18rem);
    height: 100%;
    z-index: 1;
    
    @include breakpoint {
        display: none;
    }
    
    [data-controls="prev"],
    [data-controls="next"] {
        width: 50%;
        height: 100%;
        font-size: 0;
        opacity: 0;

        &:before {
            padding: .5rem;
            border-width: 0 .3rem .3rem 0;
        }
    }

    [data-controls="prev"] {
        text-align: left;
    }

    [data-controls="next"] {
        text-align: right;
    }
}

.slider-prev,
[data-controls="prev"],
.slider-next,
[data-controls="next"] {
    @include opacity;

    &:before {
        content: '';
        display: inline-block;
        border: solid $dark;
    }
}

.slider-next,
[data-controls="next"] {
    &:before {
        margin-right: 2rem;
        margin-left: auto;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    @at-root &.sss-controls {
        right: 0;
    }
}


.slider-prev,
[data-controls="prev"] {
    &:before {
        margin-left: 2rem;
        margin-right: auto;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    @at-root &.sss-controls {
        left: 18rem;
    }
}

.slider-controls {
    color: $dark;
    font-size: .75em;
    font-weight: bold;
    letter-spacing: .05em;

    @include breakpoint {
        margin: 3rem 0!important;
        width: 100%;
        text-align: center;
    }

    .slider-next,
    .slider-prev {
        &:before {
            margin: 0;
            padding: .3rem;
            border-width: 0 .2rem .2rem 0;

            @include breakpoint {
                padding: .5rem;
            }
        }
    }

    .slider-next {
        margin-right: .2rem;
        margin-left: .5rem;

        @include breakpoint {
            margin-left: 1.5rem;
        }
    }

    .slider-prev {
        margin-left: .2rem;
        margin-right: .5rem;
        @include breakpoint {
            margin-right: 1.5rem;
        }
    }
}

///////////////// SLIDER THUMBNAILS /////////////////

.slider-thumbnails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20rem;

    @include breakpoint {
        padding-top: 1rem;
        padding-left: 0;
    }

}

.slider-thumbnails-btn {
    text-transform: uppercase;
    font-size: .8em;
    @include opacity;

    @include breakpoint {
        font-size: 1.3rem;
        text-align: right;
    }
}

.slider-thumbnail {
    margin: .5rem;
    width: calc(20% - 1rem);
    height: 15vw;
    overflow: hidden;
    @include cover;
    @include opacity;
    
    @include breakpoint {
        margin: .1rem;
        width: calc(50% - .2rem);
        height: 20vw;
    }
}

.slider-views:not(.active) {
    display: none;
}