////////////////// FONTS //////////////////
$fallback: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Helvetica Neue', 'Arial', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'sans-serif';
$path: '../fonts';
@mixin fontFace($name, $type, $weight : 400, $style : normal) {
    @font-face {
        font-family: $name;
        src: url("#{$path}/#{$name}/#{$name}-#{$type}.eot");
        src: url("#{$path}/#{$name}/#{$name}-#{$type}.eot?#iefix") format('embedded-opentype'),
        url("#{$path}/#{$name}/#{$name}-#{$type}.woff2") format('woff2'),
        url("#{$path}/#{$name}/#{$name}-#{$type}.woff") format('woff'),
        url("#{$path}/#{$name}/#{$name}-#{$type}.ttf") format('truetype'),
        url("#{$path}/#{$name}/#{$name}-#{$type}.svg##{$name}-#{$type}") format('svg');
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

////////////////// FONTS //////////////////
$font: 'Montserrat';
@include fontFace($font, 'Regular', 400);
@include fontFace($font, 'SemiBold', 700);
@include fontFace($font, 'ExtraBold', 800);

////////////////// TEXT WEIGHTS
@mixin font($style: false, $italic: false, $name: false) {
    @if $name {
        font-family: '#{$name}', $fallback;
    }
    
    @if $style == normal {
        font-weight: 400;
    }

    @else if $style == lighter {
        font-weight: lighter;
    }

    @else if $style == bold {
        font-weight: 700;
    }

    @else if $style == bolder {
        font-weight: 800;
    }

    @if $italic {
        font-style: italic;
    }
}