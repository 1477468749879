///////////////// CORE /////////////////

html {
    font-size: 62.5%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    position: relative;
    color: $grey;
    @include font(false, false, $font);
    font-size: 1.3rem;
    line-height: 1.6;
    text-transform: none;
    background-color: $white;
    user-select: none;
}

*:focus {
    outline: 0 dotted $grey !important;
    outline-offset: 0 !important;
}

.main {
    outline: 0 !important;
}

a {
    @include opacity;
}