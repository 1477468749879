///////////////// NAVIGATION /////////////////

.nav {
    color: $dark;

    @include breakpoint {
        & {
            display: none;
        }
    }

    .nav-collection {
        font-size: .88em;
    }

    .nav-ul:not(.nav-collection) {
        font-size: .8em;
    }

    .nav-ul:not(:last-child) {
        margin-bottom: 2rem;
    }

    li {
        margin-bottom: 0.5rem;
    }
}

[class^="nav"] {
    .active {
        position: relative;
        font-weight: bold;

        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            height: 1px;
            width: 100%;
            background-color: $dark;
        }

        a {
            position: relative;
            padding-right: 1rem;
            background-color: white;

            &:hover {
                opacity: 1;
            }
        }
    }
} 

///////////////// NAVIGATION MOBILE /////////////////

.nav-mobile {
    display: none;
    font-size: 1.2em;
    text-align: center;
    color: $dark;
    background-color: $lightgrey;
    overflow: hidden;
    transition: .25s ease-out height;

    .nav-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        height: 100%;
        font-size: 22px;
    }
    
    .nav-ul {
        width: 100%;
    }

    &.active {
        display: block;
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 100;
    } 

    li.active a {
        position: relative;
        padding: 0 1rem;
        background-color: $lightgrey;
    }
        

    li:not(:last-child) {
        margin-bottom: .5rem;
    }
}

/* NAV MOBILE BUTTON */

.nav-mobile-btn {
    display: none;
    position: absolute;
    left: 0;
    width: 2.5rem;
    @include opacity;

    @include breakpoint {
        & {
            display: block;

        }
        
        &.close {
            position: absolute;
            right: 2rem;
            top: 2rem;
            left: auto;
        }
    }

    &:after,
    &:before,
    span {
        content: '';
        display: block;
        margin: 3px 0;
        height: 2px;
        background-color: $dark;
        border-radius: 3px;
        transition: all .2s ease-in-out;
    }

    &.close {
        &:before {
            transform: translateY(5px) rotate(135deg);
        }

        &:after {
            transform: translateY(-5px) rotate(-135deg);
        }

        span {
            transform: scale(0);
        }
    }
}