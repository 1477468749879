////////////////// UTILS //////////////////
////////////////// HOVER ANIMATION
@mixin opacity($default: 1, $hover: 0.5) {
    opacity: $default;
    transition: 0.3s ease-out opacity;
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {
        opacity: $hover;

    }

    @include breakpoint {
        & {
            cursor: default;

            &,
            &:active,
            &:focus,
            &:hover {
                opacity: $default;
            }
        }


    }
}

@mixin no-opacity($default: 1) {
    &:active,
    &:focus,
    &:hover {
        opacity: $default;
    }
}
////////////////// DISPLAY MANAGEMENT
@mixin cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
}
@mixin access-hidden {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);

    &::after,
    &::before {
        display: none;
    }
}
.hidden {
    @include access-hidden;
}