///////////////// SINGLE LAYOUT /////////////////

.single {
    padding-left: 25rem;
    max-width: 90rem;

    @include breakpoint {
        margin: 0 auto;
        padding: 2rem 0 0;
        max-width: 45rem;
    }
}

.title {
    margin-bottom: 3rem;
    color: $dark;
    font-size: 1.5em;
    font-weight: bold;
}