///////////////// DARK THEME /////////////////

.dark-btn {
    position: fixed;
    top: 1.5rem;
    right: 2rem;
    width: 2rem;
    @include opacity(.3);
    z-index: 99;

    @include breakpoint {
        position: absolute;
        top: 2.5rem;
    }
}

///////////////// DARK THEME: ACTIVE /////////////////
.dark {
    color: $light;
    background-color: $dark;

    .title,
    .nav,
    .header-title,
    .slider-controls,
    .slider-title {
        color: $light;
    }

    .nav .active a {
        background-color: $dark;
    }

    .nav-mobile,
    .nav-mobile .active a {
        color: inherit;
        background-color: $grey;
    }

    [class^="nav"] .active:before,
    .nav-mobile-btn:after, 
    .nav-mobile-btn:before, 
    .nav-mobile-btn span {
        background-color: $light;
    }

    .slider-prev,
    [data-controls="prev"],
    .slider-next,
    [data-controls="next"] {
        &:before {
            border-color: $light;
        }
    }

    .slider-overlay {
        background-color: $dark;
    }

    .form-button {
        color: $dark;
        background-color: $light;
    }

    .form-input {
        background-color: $grey;
        border: .1rem solid lighten($grey, 10%);
    }

    .dark-btn {
        opacity: .8;
        -webkit-filter: invert(1);
        filter: invert(1);
    }
}

